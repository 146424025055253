<template>
  <div class="recruitInfoCapture">
    <div class="menu_select">
      <div class="datePicker">
        <a-radio-group  v-model="Functiontype" style="display:flex;width:100%;border-bottom:6px rgba(240, 242, 245, 1) solid" @change="searchOk">
          <!-- <a-radio-button value="app">
            &nbsp;&nbsp;APP
          </a-radio-button> -->
          <a-radio-button style="display: flex;justify-content:center" value="mina">
            <span>
              小程序
            </span>
          </a-radio-button>
          <a-radio-button value="web"  style="display: flex;justify-content:center">
            官网
          </a-radio-button>
            <a-radio-button value="app"  style="display: flex;justify-content:center;width:240px;font-size:12px">
            新版APP(未上线)
          </a-radio-button>

        </a-radio-group>
      </div>
      <a-menu style="width: 100%;background-color: #fff;" mode="inline" v-model="menu">
        <a-menu-item
          v-for="(item, index) in menuList"
          :key="index"
          @click="leftChange(item.advCode,item.title)"
          >{{ item.title }}
        </a-menu-item
        >
      </a-menu>
    </div>
    <div class="InfoTable">
      <a-table
          :columns="columns"
          style="min-height: 500px; background-color: #fff;"
          :data-source="HeplTable"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
        <span slot="icon" slot-scope="text, item, index">
            <img style="width: 50px;height: 50px" :src="text">
        </span>
        <span slot="bannerLocation" slot-scope="text, item, index">
          <span v-if="text === 'mina'">小程序/{{title}}</span>
          <!-- <span v-if="text === 'app'">APP/{{title}}</span> -->
          <span v-if="text === 'web'">官网/{{title}}</span>
          <span v-if="text === 'app'">APP/{{title}}</span>

        </span>
        <span slot="sort" slot-scope="text, item, index">
          <a v-if="index === 0 && pagination.pageNum == 1" @click="sortList('down',item)" style="margin-left:5px">下移</a>
          <a v-else-if="index === HeplTable.length -1 && pagination.pageNum == Math.ceil(pagination.total / pagination.pageSize)" @click="sortList('up',item)">上移</a>
          <span v-else>
            <a @click="sortList('up',item)">上移</a>
            <a @click="sortList('down',item)" style="margin-left:5px">下移</a>
          </span>
        </span>
        <a slot="action" slot-scope="text,item">
            <a-popconfirm title="确认移除吗？" ok-text="是" cancel-text="否" @confirm="delInfo(item)">  
              <a style="margin-right: 8px">移除</a>
            </a-popconfirm>
          </a>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  getBannerLocation,getRecommend,moveRecommend,delRecommend
} from "../../../service/HomeFunctionManagement";
export default {
data(){
  return{
    menu: [0],
    menuList:[{
      name:"随便写的",
      id:2
    }],
    Functiontype:'mina',
    HeplTable:[],
    columns: [
      {
        title: "图片",
        dataIndex: "displayUrl",
        key: "displayUrl",
        align:"center",
        scopedSlots: { customRender: "icon" },
      },
      {
        title: "位置",
        dataIndex: "bannerLocation",
        key: "bannerLocation",
        align:"center",
        scopedSlots: { customRender: "bannerLocation" },
      },
      {
        title: "备注",
        dataIndex: "notes",
        key: "notes",
        align:"center",
        // scopedSlots: { customRender: "bannerLocation" },
      },
      {
        title: "发布时间",
        dataIndex: "publishTime",
        key: "publishTime",
        align:"center",
        // scopedSlots: { customRender: "funcLocation" },
      },
      {
        title: "排序",
        align:"center",
        scopedSlots: { customRender: "sort" },
      },
      {
        title: "操作",
        fixed:'right',
        width: 150,
        align:'center',
        scopedSlots: { customRender: 'action' },
      },
    ],
    pagination: {
      total: 0,
      current: 1,
      pageSize: 10,
      pageNum: 1,
    },
    advCode:'',
    title:'',
  }
},
created() {
  this.getMenu();
},
methods:{
  // 删除
  async delInfo(record){
    const response = await delRecommend(record.id)
    if (response.code === 0) {
      this.$message.success("移除成功");
      this.onloads()
    } else {
      this.$message.warning(response.message);
    }
  },
  // 排序
  async sortList(updown,item){
    const response = await moveRecommend(updown,item.id)
    if (response.code === 0) {
      this.onloads()
      this.$message.success('操作成功')
    } else {
      this.$message.warning(response.message);
    }
  },
  leftChange(advCode,title){
    this.pagination.pageNum = 1
    this.pagination.pageSize = 10
    this.advCode = advCode
    this.title = title
    this.onloads()
  },
  searchOk(){
    this.menu = [0]
    this.getMenu()
  },
  // 获取菜单
  async getMenu() {
    const res = await getBannerLocation(this.Functiontype);
    if (res.code === 0) {
      this.menuList = res.data;
      if (this.menuList.length !== 0){
        this.advCode = this.menuList[0].advCode  //给onload传参
        this.title = this.menuList[0].title
      }else{
        this.advCode = ''
        this.title = ''
      }
      this.onloads();
    }
  },
  // 默认加载第一个模块内容
  async onloads() {
    let data = {
      advCode: this.advCode,
      bannerLocation: this.Functiontype,
      pageNo: this.pagination.pageNum,
      pageSize: this.pagination.pageSize,
    };
    const res = await getRecommend(data);
    if (res.code == 0) {
      this.HeplTable = res.data;
      this.pagination.total = res.count;
    }
  },
}
}
</script>

<style lang="scss" scoped>
.recruitInfoCapture{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  display: flex;
  height: 100vh;
  // justify-content: ;
  .header{
    height: 100px;
    background: white;
    padding: 20px;
    ::v-deep .ant-select-selection{
      border-radius: 0!important;
    }
  }
  .table{
    margin-top: 10px;
    background: white;
    padding: 20px;
  }
  .menu_select {
    background-color: white;
    // width: 17.9%;
    width:23%;
    // min-height: 750px;
    .datePicker{
      width:100%;
    }
  }
  .InfoTable {
    background-color: white;
    width: 78%;
    margin-left:10px;
    // min-height: 750px;
    .navTop {
      width: 100%;
      height: 100px;
      border-bottom: 15px solid rgba(240, 242, 245, 1);
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .nav {
      width: 100%;
    }
  }
}
::v-deep .ant-table-tbody > tr > td {
  padding: 10px 10px;
  overflow-wrap: break-word;
}

::v-deep .ant-radio-button-wrapper{
  width:50%;
  padding: 0 9px 0 9px;
} 
</style>